<script setup lang="ts">
import type { SliceInterface, VoixSelectFieldInterface, VoixTextFieldInterface } from '@voix/types'
import type { Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'

defineProps<{
  slice: SliceInterface
  fields: {
    subtitle: VoixTextFieldInterface
    title: VoixTextFieldInterface
    headingTag: VoixSelectFieldInterface
    headingSize: VoixSelectFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Cards Slider', group: 'Cards', layouts: ['VoixMarketing'] },
  slots: [{ name: 'default', allowedElements: [] }],
  description: 'Slider system for cards on Voix.ooo',
  preview: 'SlicesCardsSlider.jpg',
  fields: {

    subtitle: {
      type: 'text',
      label: 'Subtitle',
      group: 'Title',
    },
    title: {
      type: 'text',
      label: 'Title',
      group: 'Title',
    },
    headingTag: {
      type: 'select',
      label: 'Heading Tag',
      group: 'Title',
      options: {
        h1: 'H1',
        h2: 'H2',
        h3: 'H3',
        h4: 'H4',
        h5: 'H5',
        h6: 'H6',
        div: 'div',
      },
      default: 'h2',
    },
    headingSize: {
      type: 'select',
      label: 'Heading Size',
      group: 'Title',
      options: {
        'heading-1': 'Heading 1',
        'heading-2': 'Heading 2',
        'heading-3': 'Heading 3',
        'heading-4': 'Heading 4',
        'heading-5': 'Heading 5',
        'heading-6': 'Heading 6',
        '': 'None',
      },
      default: 'heading-2',
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

const swiper: Ref<SwiperType | null> = ref(null)

function onSwiper(swiperInstance: SwiperType) {
  swiper.value = swiperInstance
}

function next() {
  if (swiper.value)
    swiper.value.slideNext()
}

function prev() {
  if (swiper.value)
    swiper.value.slidePrev()
}
</script>

<template>
  <div class="relative container mx-auto">
    <div class="p-8 border border-neutral-800">
      <component :is="fields.headingTag.value" :class="[fields.headingSize.value]">
        <div class="subtitle">
          {{ fields.subtitle.value }}
        </div>
        {{ fields.title.value }}
      </component>
    </div>
    <Swiper
      slides-per-view="auto"
      :loop="true"
      :centered-slides="false"
      class="center-slider border border-neutral-800  overflow-hidden"
      @swiper="onSwiper"
    >
      <SwiperSlide v-for="(s, key) in slice.elements" :key="key" class="w-full lg:w-[700px] flex-none">
        <VoixSlice :slice="s as SliceInterface" :slice-index="key" />
      </SwiperSlide>
    </Swiper>

    <div class="flex items-center divide-x divide-neutral-800 border border-neutral-800 ">
      <button>
        <Icon name="heroicons:chevron-left-20-solid" class="w-16 h-16" @click="prev" />
      </button>
      <button>
        <Icon name="heroicons:chevron-right-20-solid" class="w-16 h-16" @click="next" />
      </button>
    </div>
  </div>
</template>

<style>
.swiper-wrapper {
  @apply flex;
}
.swiper-slide {
  @apply px-8;
}
</style>
